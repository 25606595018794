import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation, withParams } from './../../Utils/Navigater';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized'
import DatePickerInput from '../../Components/Forms/Inputs/DatePickerInput';
const FormValidation = Yup.object().shape({
   claim_first_name: Yup.string().required('First name is required.'),
   claim_last_name: Yup.string().required('Last name  is required.'),
   category_id: Yup.string().required('Category id  is required.'),
   claim_description: Yup.string().required('Claim description  is required.'),
   claim_lost_date: Yup.string().required('Approximate lost date  is required.'),
});

let Index = (props) => {
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   let [account, setAccount] = useState({});
   let [claim_page_enabled, setClaim_page_enabled] = useState(false);
   let [getCategories, setGetCategories] = useState([]);
   let { id } = props.params;
   useEffect(() => {
      apiCalls(id)
   }, [])
   let apiCalls = async (id) => {
      await CategoriesApi(id);
      await getAccountDetails(id);
      await getData(id);
   }

   let getAccountDetails = async (id) => {
      try {
         AppApi.getPublicAccountDetails(id).then(res => {
            if (res.status == 200) {
               setAccount(res.data)
               setClaim_page_enabled(res.data.claim_page_enabled)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getData = async (id) => {
      try {
         AppApi.getVenuesPublic(id).then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let CategoriesApi = async (id) => {
      try {
         AppApi.getCategoriesPublic(id).then(res => {
            if (res.status == 200) {
               setGetCategories(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   return (
      <>
         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <>
               {claim_page_enabled ? (
                  <div className="container-fluid mt-5">
                     <div className="row">
                        <div className="col-md-4">
                           <div class="logo">
                              <img src={account.claim_page_logo_url} className='publicPictures' />
                           </div>
                           <br></br>
                           <div dangerouslySetInnerHTML={{ __html: account?.claim_page_header_text }}></div>
                           <br></br>
                        </div>
                     </div>
                     <Formik
                        initialValues={{
                           claim_first_name: "",
                           claim_last_name: "",
                           claim_phone: "",
                           claim_email: "",
                           venue_id: "",
                           claim_lost_location: "",
                           category_id: "",
                           claim_description: "",
                           claim_lost_date: "",
                        }}
                        validationSchema={FormValidation}
                        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
                           console.log("🚀 ~ file: Index.js ~ line 47 ~ Index ~ values", values)
                           var data = new FormData();
                           data.append('claim[claim_first_name]', values.claim_first_name);
                           data.append('claim[claim_last_name]', values.claim_last_name);
                           data.append('claim[claim_phone]', values.claim_phone);
                           data.append('claim[claim_email]', values.claim_email);
                           data.append('claim[venue_id]', values?.venue_id);
                           data.append('claim[claim_lost_location]', values.claim_lost_location);
                           data.append('claim[category_id]', values.category_id);
                           data.append('claim[claim_description]', values.claim_description);
                           data.append('claim[claim_lost_date]', values.claim_lost_date);
                           try {
                              AppApi.publicClaims({ data, id }).then(result => {
                                 if (result && result.status == 200) {
                                    toast.success('Thank you - your claim has been received.', {
                                       autoClose: 5000,
                                    });
                                    resetForm()
                                 } else {
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                 }
                                 setSubmitting(false)
                              }).catch(error => {
                                 setSubmitting(false)
                                 try {
                                    let message = error.response.data.errors[0]
                                    toast.error(message ? message : 'Server Error!', {
                                       autoClose: 5000,
                                    });
                                 } catch (error) {
                                    console.log("🚀 ~ file: Index.js ~ line 257 ~ ItemsApi.claimItems ~ error", error)
                                    
                                 }
                              })
                           } catch (error) {
                              console.log("🚀 ~ file: Index.js ~ line 262 ~ Index ~ error", error)
                              setSubmitting(false)
                              toast.error('Server error.', {
                                 autoClose: 5000,
                              });
                              console.log("server error", error.message)
                           }

                        }}
                     >
                        {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                           <Form >
                              <div className="row">
                                 <div className="col-md-4">
                                    <div className='well'>
                                       <div class="help-block">Your Contact Information</div>
                                       <div class="form-group d-flex align-items-center text-center">
                                          <label class="col-sm-4 control-label required" for="claim_claim_first_name">First Name</label>
                                          <div class="col-sm-8">
                                             <Field class="form-control" placeholder="First Name" maxlength="25" size="25" type="text" name="claim_first_name" id="claim_claim_first_name" />
                                             <ErrorMessage component="small" className='text-danger text-start d-block ' name="claim_first_name" />
                                          </div>
                                       </div>
                                       <div class="form-group d-flex align-items-center text-center ">
                                          <label class="col-sm-4 control-label required" for="claim_claim_last_name">Last Name</label>
                                          <div class="col-sm-8">
                                             <Field class="form-control" placeholder="Last Name" maxlength="25" size="25" type="text" name="claim_last_name" id="claim_claim_last_name" />
                                             <ErrorMessage component="small" className='text-danger text-start d-block ' name="claim_last_name" />
                                          </div>
                                       </div>
                                       <div class="form-group d-flex align-items-center text-center">
                                          <label class="col-sm-4 control-label" for="claim_claim_phone">Phone</label>
                                          <div class="col-sm-8">
                                             <Field class="form-control" placeholder="Phone Number" maxlength="18" size="18" type="text" name="claim_phone" id="claim_claim_phone" />
                                             <ErrorMessage component="small" className='text-danger text-start d-block ' name="claim_phone" />
                                          </div>
                                       </div>
                                       <div class="form-group d-flex align-items-center text-center">
                                          <label class="col-sm-4 control-label required" for="claim_claim_email">Email</label>
                                          <div class="col-sm-8">
                                             <Field class="form-control" placeholder="Email" maxlength="80" size="80" type="text" name="claim_email" id="claim_claim_email" />
                                             <ErrorMessage component="small" className='text-danger text-start d-block ' name="claim_email" />
                                          </div>
                                       </div>
                                       <div class="help-block">Details of Lost Item(s)</div>
                                       {account?.venue_enabled && (
                                          <div class="form-group d-flex align-items-center text-center">
                                             <label class="col-sm-4 control-label required" for="claim_venue_id">Venue</label>
                                             <div class="col-sm-8">
                                                <select className='form-control'
                                                   value={values.venue_id}
                                                   onChange={(event) => { setFieldValue("venue_id", event.target.value) }}
                                                >
                                                   <option value="" selected="selected">-- Pick A Venue --</option>
                                                   {data.filter((data) => !data.is_hidden).map((data, index) => {
                                                      return (
                                                         <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                      )
                                                   })}
                                                </select>
                                                <ErrorMessage component="small" className='text-danger text-start d-block ' name="venue_id" />
                                             </div>
                                          </div>
                                       )}
                                       <div class="form-group d-flex align-items-center text-center">
                                          <label class="col-sm-4 control-label" for="claim_claim_lost_location">Lost Location</label>
                                          <div class="col-sm-8">
                                             <Field class="form-control" placeholder="Lost Location" type="text" name="claim_lost_location" />
                                             <ErrorMessage component="small" className='text-danger text-start d-block ' name="claim_lost_location" />
                                          </div>
                                       </div>
                                       <div class="form-group d-flex align-items-center text-center">
                                          <label class="col-sm-4 control-label required" for="claim_category_id">Category</label>
                                          <div class="col-sm-8">
                                             <select class="form-control" name="category_id" id="claim_category_id"
                                                value={values.category_id}
                                                onChange={(event) => { setFieldValue("category_id", event.target.value) }}>
                                                <option value="" selected="selected">-- Pick A Category --</option>
                                                {getCategories.filter((data) => !data.is_hidden).map((data, index) => {
                                                   return (
                                                      <option key={data.id} value={data.id}>{data.category_description}</option>
                                                   )
                                                })}
                                             </select>
                                             <ErrorMessage component="small" className='text-danger text-start d-block ' name="category_id" />
                                          </div>
                                       </div>
                                       <div class="form-group d-flex align-items-center text-center">
                                          <label class="col-sm-4 control-label required" for="claim_claim_description">Description of lost item(s)</label>
                                          <div class="col-sm-8">
                                             <textarea class="form-control"
                                                value={values.claim_description}
                                                onChange={(event) => { setFieldValue("claim_description", event.target.value) }}
                                                placeholder="(Enter a brief, concise description)"  ></textarea>
                                             <ErrorMessage component="small" className='text-danger text-start d-block ' name="claim_description" />
                                          </div>
                                       </div>
                                       <div class="form-group d-flex align-items-center text-center">
                                          <label class="col-sm-4 control-label required" for="claim_claim_lost_date">Approximate Lost Date</label>
                                          <div class="col-sm-8">
                                             <DatePickerInput
                                                onChange={setFieldValue}
                                                name="claim_lost_date"
                                                value={values.claim_lost_date}
                                             />
                                             <ErrorMessage component="small" className='text-danger text-start d-block ' name="claim_lost_date" />
                                          </div>
                                       </div>
                                       <div class="row">
                                          <label class="col-sm-4"  > </label>
                                          <div class="  col-sm-8">
                                             <Field type="submit" name="commit" value="Submit" disabled={isSubmitting} class="btn btn-primary" />
                                          </div>
                                       </div>
                                       <div class="help-block">
                                          <br />
                                          <img src="/assets/images/repoapp_powered_by.png" style={{ width: "100px" }} alt="" />
                                          <a href="https://www.repoapp.com/privacy" target="_blank" rel="noopener noreferrer"><sup><small>Privacy</small></sup></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     </Formik>

                  </div>
               ) : (
                  <UnAuthorized />
               )}

            </>
         )}


      </>

   )
}

export default withParams(withNavigation(Index))