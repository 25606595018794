import React, { useEffect, useState, useRef } from 'react';
import { json, Link } from 'react-router-dom'
import { withNavigation, withParams } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ItemsApi from '../../Api/ItemsApi';
import ClaimsApi from '../../Api/ClaimItemsApi';
import AppApi from '../../Api/AppApi';
import ShipmentApi from 'Api/ShipmentApi';
import Url from '../../Utils/Routes'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import moment from 'moment';
import SignatureCanvas from "react-signature-canvas";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import ItmesAppNavigater from '../../Components/AppComponents/ItmesAppNavigater';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';
import Parcel from '../../Components/AppComponents/Parcel';

const FormValidation = Yup.object().shape({
   // claim_id: Yup.string().required('Claim is required.'),
   disposition_id: Yup.string().required('Disposition  is required.'),
   // item_claim_email: Yup.string().required('Claim email  is required.').email("Email is not valid."),
   // item_claim_first_name: Yup.string().required('Claim first name  is required.'),
   // item_claim_last_name: Yup.string().required('Claim last name  is required.'),
   // item_claim_phone: Yup.string().required('Claim phone number  is required.'),
   // item_release_address: Yup.string().required('Release address  is required.'),
   // item_release_notes: Yup.string().required('Release note  is required.'),
   // item_release_signature: Yup.string().required('Release signature  is required.'),
});
let Index = (props) => {
   const shipmentPermissions = useSelector((state) => state.shipmentPermissions);
   let signaturePadRef = useRef(null);
   let [dispositions, setDispositions] = useState([]);
   let [claimData, setDataClaim] = useState([]);
   let [webOptions, setWebOptions] = useState({});
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState({});
   let [form, setForm] = useState(1);
   let [email, setEmail] = useState("");
   let [disabled, setDisable] = useState(false);
   let [disabledUpdate, setDisableUpdate] = useState(false);
   let [disableUpdateButton, setDisableUpdateButton] = useState(false);

   let { id } = props.params;
   useEffect(() => {
      Promise.all([webOptionsApi(), getData(id), getDispositions(), getClaimItems(),]).then((values) => {
         console.log(values)
         setWebOptions(values[0])
         setData(values[1]);
         setDispositions(values[2]);
         setDataClaim(values[3])
         setApiLoading(false)
      });
   }, [id])

   useEffect(() => {
      if (sessionStorage.getItem('showShipmentRequestToast') === 'true') {
         toast.success('Email sent to owner.', {
            autoClose: 5000,
        });
        sessionStorage.removeItem('showShipmentRequestToast');
      }
   }, [])

   let webOptionsApi = async () => {
      try {
         let data = await AppApi.clientAccountOptions().then(res => {
            if (res.status == 200) {
               return res.data
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
            return []
         })
         return data
      } catch (error) {
         return []
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getData = async (id) => {
      try {
         let data = await ItemsApi.itemsDetails(id).then(res => {
            if (res.status == 200) {
               setEmail(res.data.item_claim_email);
               if (res?.data?.item_status?.item_status_code == "R") {
                  setDisable(true)
               }
               return res.data
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
            return []
         })
         return data
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         return data
      }
   }
   let getDispositions = async () => {
      try {
         let data = await AppApi.getDispositions().then(res => {
            if (res.status == 200) {
               return res.data
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
            return []
         })
         return data
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         return []
      }
   }
   let getClaimItems = async () => {
      try {
         let data = await AppApi.claimsTopAvailable().then(res => {
            if (res.status == 200) {
               return res.data
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
            return []
         })
         return data
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         return []
      }
   }
   let updateItemDetails = async (setSubmitting, email, claim_id, item_claim_first_name, item_claim_last_name, item_claim_phone, item_release_address, item_release_notes, disposition_id) => {
      var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
      if (email) {
         if (mailFormat.test(email)) {
            try {
               let apiData = {
                  id: id,
                  data: { item_claim_email: email, claim_id, item_claim_first_name, item_claim_last_name, item_claim_phone, item_release_address, item_release_notes, disposition_id }
               }
               setSubmitting(true);
               ItemsApi.updateItems(apiData).then(res => {
                  if (res.status == 200) {
                     toast.success('Item information updated.', {
                        autoClose: 5000,
                     });
                     setEmail(email);
                  }
                  setSubmitting(false);
               }).catch(error => {
                  const errorMessages = error.response.data.errors
                  if (errorMessages) {
                     errorMessages.forEach((item) => {
                        toast.error(item, {
                           autoClose: 5000,
                        });
                     });
                  }
                  setSubmitting(false)
               })
            } catch (error) {
               toast.error('Server error.', {
                  autoClose: 5000,
               });
               setSubmitting(false)
            }
         } else {
            toast.error('Email is not valid.', {
               autoClose: 5000,
            });
         }
      } else {
         toast.error('Email id is required.', {
            autoClose: 5000,
         });
      }

   }
   let revertRelease = async () => {
      try {
         ItemsApi.revertRelease(id).then(res => {
            if (res.status == 200) {
               props.navigate(Url.EditNewItems(data.id))
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let searchById = (setFieldValue, id) => {
      let filterData = claimData.filter((data) => data.id === id);
      if (filterData.length > 0) {
         let filData = filterData[0]
         setFieldValue("item_claim_first_name", filData.claim_first_name);
         setFieldValue("item_claim_last_name", filData.claim_last_name);
         setFieldValue("item_claim_phone", filData.claim_phone);
         setFieldValue("item_claim_email", filData.claim_email);
         setFieldValue("claim_notes", filData.claim_notes);
      } else {
         ClaimsApi.getClaim(id).then((res)=>{
         if (res.status === 200){
            let data = res.data;
            setFieldValue("item_claim_first_name", data.claim_first_name);
            setFieldValue("item_claim_last_name", data.claim_last_name);
            setFieldValue("item_claim_phone", data.claim_phone);
            setFieldValue("item_claim_email", data.claim_email);
            setFieldValue("claim_notes", data.claim_notes);
         }
         else{
            toast.error('Record not found, incorrect ID or record may have been deleted.', {
               autoClose: 5000,
            });
         }
         }).catch((error) => {} )
      }
   }
   let updateEditMode = () => {
      setDisableUpdate(true)
   }
   return (
      <>
         <UserHeader />
         <ItmesAppNavigater />
         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <div className="container-fluid mt-3">
               {data.id && (
                  <div className="row mt-3">
                     <div className="col-md-8 co-12">
                        {data?.item_status?.item_status_code == "R" && (
                           <div class="alert alert-warning ng-binding" ng-show="item.item_status_code == 'R'"  >
                              <p class="lead">Item Released</p>
                              {data.item_bulk_release_id && (
                                 <div ><i class="fa fa-info-circle"></i> Item was part of a Bulk Release event <Link to={Url.ItemBulkRelease}>See Details</Link> </div>
                              )}
                              <i class="fa fa-user"></i> {data?.release_user?.email}<br />
                              <i class="fa fa-calendar"></i> <span id="item_release_datetime" class="ng-binding">{moment(data?.item_release_datetime).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span> <br />
                           </div>
                        )}
                        <Formik
                           initialValues={{
                              claim_id: data.claim_id == null ? "" : data.claim_id,
                              disposition_id: data.disposition_id == null ? "" : data.disposition_id,
                              item_claim_first_name: data.item_claim_first_name,
                              item_claim_last_name: data.item_claim_last_name,
                              item_claim_email: data.item_claim_email,
                              item_claim_phone: data.item_claim_phone,
                              item_release_address: data.item_release_address,
                              item_release_notes: data.item_release_notes,
                              item_release_signature: data.item_release_signature,
                           }}
                           validationSchema={FormValidation}
                           onSubmit={(values, { setSubmitting, setFieldError }) => {
                              setSubmitting(false);
                              let item_release_signature = signaturePadRef.toDataURL();
                              let signature = true
                              if (webOptions.require_signature) {
                                 if (!signaturePadRef.isEmpty()) {
                                    signature = true
                                 } else {
                                    signature = false
                                 }
                              }
                              if (signature) {
                                 let data = { ...values, item_release_signature };
                                 Swal.fire({
                                    title: 'Are you sure?',
                                    showDenyButton: false,
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes',
                                    denyButtonText: `Don't save`,
                                 }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                       setSubmitting(true);
                                       try {
                                          let apiObjects = {
                                             id: id,
                                             data: data
                                          }
                                          ItemsApi.claimItems(apiObjects).then(result => {
                                             if (result && result.status == 200) {
                                                toast.success('Release information updated.', {
                                                   autoClose: 5000,
                                                });
                                                props.navigate(Url.EditNewItems(id));
                                             } else {
                                                toast.error('Server error.', {
                                                   autoClose: 5000,
                                                });
                                             }
                                             setSubmitting(false)
                                          }).catch(error => {
                                             setSubmitting(false)
                                             try {
                                                let message = error.response.data.errors[0]

                                                toast.error(message ? message : 'Server Error!', {
                                                   autoClose: 5000,
                                                });
                                             } catch (error) {
                                                
                                             }
                                          })
                                       } catch (error) {
                                          setSubmitting(false)
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                          console.log("server error", error.message)
                                       }
                                    } else if (result.isDenied) {
                                       setSubmitting(false);
                                    }
                                 })
                              } else {
                                 setSubmitting(false)
                                 setFieldError("item_release_signature", "Signature is required.")
                              }


                           }}
                        >
                           {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                              <Form >
                                 <div className="panel border panel-default">
                                    <div className="panel-heading d-flex justify-content-between">
                                       <div>
                                          <h3 className="panel-title">Release Details  - ID: {id} </h3>
                                       </div>

                                    </div>

                                    <div className="panel-body p-2 px-4">
                                       <div className="row">
                                          <div className="col-12">
                                             <div className="alert alert-info">
                                                <i className="fa fa-info-circle"></i> All fields are optional. If customer's claim ID is known, enter it below or pick it from the list of recent claims.
                                             </div>
                                             <div class="panel-body p-2 border" style={{ background: "#eeeeee" }}>
                                                <p>     {data.item_description}</p>
                                             </div>
                                          </div>
                                       </div>

                                       <div className="row mt-2">
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="font-weight-bold  ">Select Corresponding Claim
                                                   <ToolTipContainer
                                                      view=""
                                                      index={1}
                                                      placement="right"
                                                      icon="text-info"
                                                      title="Select Corresponding Claim"
                                                      des="Only last 50 claims appear in this list. Select the corresponding claim or enter the Claim ID if known. After releasing the item, the corresponding claim will be deleted."
                                                   />
                                                </label>
                                                <select className='form-control rounded-0' name=""
                                                   values={values.claim_id}
                                                   disabled={disabledUpdate ? disabledUpdate : disabled}
                                                   onChange={(event) => {
                                                      setFieldValue("claim_id", event.target.value);
                                                      searchById(setFieldValue, event.target.value);
                                                   }}
                                                   value={values.category_id}
                                                >
                                                   <option value="">-- Pick a Recent Claim --</option>
                                                   {claimData.map((data, index) => {
                                                      return (
                                                         <option key={data.id} value={data.id}>{data.id}-{data.claim_description}</option>
                                                      )
                                                   })}
                                                </select>
                                                <ErrorMessage component="small" className='text-danger' name="claim_id" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <label className="font-weight-bold ">or Enter Claim ID</label>
                                             <div class="input-group mb-3">
                                                <Field name="claim_id" type="text" disabled={disabledUpdate ? disabledUpdate : disabled} class="form-control" placeholder="Enter claim ID if known" />
                                                <div class="input-group-append"
                                                   onClick={() => {
                                                      if (values.claim_id) {
                                                         searchById(setFieldValue, values.claim_id)
                                                      }
                                                   }}
                                                >
                                                   <span class="input-group-text pointer" id="basic-addon2"
                                                   >Search</span>
                                                </div>
                                             </div>

                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="font-weight-bold  ">First Name</label>
                                                <Field type="text" className='form-control rounded-0' name="item_claim_first_name"
                                                   disabled={disabledUpdate ? disabledUpdate : disabled}
                                                />
                                                <ErrorMessage component="small" className='text-danger' name="item_claim_first_name" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="font-weight-bold  ">Last Name</label>
                                                <Field type="text"
                                                   disabled={disabledUpdate ? disabledUpdate : disabled}
                                                   className='form-control rounded-0' name="item_claim_last_name" />
                                                <ErrorMessage component="small" className='text-danger' name="item_claim_last_name" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="font-weight-bold">Email</label>
                                                <Field type="email"
                                                   disabled={disabledUpdate ? disabledUpdate : disabled}
                                                   className='form-control rounded-0' name="item_claim_email"
                                                />
                                                <ErrorMessage component="small" className='text-danger' name="item_claim_email" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="font-weight-bold  ">Phone</label>
                                                <Field type="text" className='form-control rounded-0'
                                                   disabled={disabledUpdate ? disabledUpdate : disabled}
                                                   name="item_claim_phone" />
                                                <ErrorMessage component="small" className='text-danger' name="item_claim_phone" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="font-weight-bold  ">Address</label>
                                                <Field type="text" className='form-control rounded-0'
                                                   disabled={disabledUpdate ? disabledUpdate : disabled}
                                                   name="item_release_address" />
                                                <ErrorMessage component="small" className='text-danger' name="item_release_address" />
                                             </div>
                                          </div>

                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="font-weight-bold  required ">Disposition</label>
                                                <select className='form-control rounded-0' name=""
                                                   disabled={disabled}
                                                   onChange={(event) => setFieldValue("disposition_id", event.target.value)}
                                                   value={values.disposition_id}

                                                >
                                                   <option value="">- Pick Disposition -</option>
                                                   {dispositions.map((data, index) => {
                                                      return (
                                                         <option key={data.id} value={data.id}>{data.disposition_description}</option>
                                                      )
                                                   })}
                                                </select>
                                                <ErrorMessage component="small" className='text-danger' name="disposition_id" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="font-weight-bold  ">Notes</label>
                                                <textarea name="" className='form-control' rows="2"
                                                   disabled={disabled}
                                                   value={values.item_release_notes}
                                                   onChange={(event) => setFieldValue("item_release_notes", event.target.value)}
                                                ></textarea>
                                                <ErrorMessage component="small" className='text-danger' name="item_expire_after" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="font-weight-bold">Signature</label>
                                                {values.item_release_signature ? (
                                                   <img src={values.item_release_signature} className="w-100" alt="" />
                                                ) : (
                                                   <>
                                                      <div className='border signatureContainer'>
                                                         <SignatureCanvas
                                                            penColor="black"
                                                            ref={(ref) => {
                                                               signaturePadRef = ref;
                                                            }}
                                                            canvasProps={{ className: "sigCanvas" }}
                                                         />
                                                      </div>
                                                      <div className='btn btn-info btn-sm mt-2' onClick={() => {
                                                         setFieldValue("item_release_signature", "")
                                                         // console.log(signaturePadRef.toDataURL())
                                                         signaturePadRef.clear()
                                                      }} type='button'>Reset</div>
                                                   </>
                                                )}
                                                <ErrorMessage component="small" className='text-danger d-block' name="item_release_signature" />
                                             </div>
                                          </div>
                                       </div>

                                    </div>

                                    <div className="panel-footer">
                                       <div>
                                          {isSubmitting ? <button className="btn btn-info" >Loading....</button> : (
                                             <>
                                                {data.item_status?.item_status_code !== "R" && (
                                                   <button className="btn btn-success me-1" disabled={isSubmitting} type="submit"> {isSubmitting ? "Loading..." : (<><i className="fa fa-check"></i>  Release Item</>)} </button>
                                                )}
                                                {/* {data.item_status?.item_status_code == "R" && ( */}
                                                <button class="btn btn-success me-1" type='button'
                                                   disabled={isSubmitting ? true : disableUpdateButton}
                                                   onClick={(() => {
                                                      updateItemDetails(setSubmitting, values.item_claim_email, values.claim_id, values.item_claim_first_name, values.item_claim_last_name, values.item_claim_phone, values.item_release_address, values.item_release_notes, values.disposition_id)
                                                   })}
                                                > <i class="fa fa-check me-1"></i>{isSubmitting ? "Loading..." : "Update Item"} </button>
                                                {/* )} */}
                                                {data.item_status?.item_status_code == "R" && (
                                                   <button class="btn btn-danger" type='button'
                                                      disabled={isSubmitting}
                                                      onClick={(() => {
                                                         setSubmitting(true)
                                                         revertRelease()
                                                      })}
                                                   > <i class="fa fa-undo me-1"></i>{isSubmitting ? "Loading..." : "Remove Release"} </button>
                                                )}
                                                {!isSubmitting && (
                                                   <Link to={Url.RepoAppDashboard} className="btn btn-default  ms-1"  >Cancel</Link>
                                                )}
                                             </>
                                          )}

                                       </div>
                                    </div>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                     <div className="col-md-4 co-12">
                        {shipmentPermissions ? (
                           <>
                              {data?.shipment?.status != "cancelled" ? (<>{email ? (
                                 <> {data?.id && <Parcel id={data.id} email={email} updateEditMode={updateEditMode} disableUpdateButton={disableUpdateButton} status={data.item_status?.item_status_code} />}</>
                              ) : (
                                 <div className="alert alert-warning">
                                    Please update email to utilize shipping.
                                 </div>
                              )}</>) : (<>
                                 <div className="alert alert-danger">
                                    Shipment has been cancelled by the user
                                 </div>
                              </>)}

                              {data?.shipment?.status && data?.shipment?.status != 'paid' && (
                                 <div className="justify-content-center d-flex">
                                    <button
                                       className="btn btn-primary me-1"
                                       type="submit"
                                       onClick={() => {
                                          Swal.fire({
                                             title: 'Are you sure?',
                                             text: 'This will send an email to item owner, Continue?',
                                             showDenyButton: false,
                                             showCancelButton: true,
                                             confirmButtonText: 'Yes',
                                          }).then((result) => {
                                             if (result.isConfirmed) {
                                                   try {
                                                      ShipmentApi.resendShipmentRequest(data?.shipment_id).then(result => {
                                                         if (result && result.status == 200) {
                                                            sessionStorage.setItem('showShipmentRequestToast', 'true');
                                                            window.location.reload();
                                                        }
                                                      }).catch(error => {
                                                         try {
                                                               let message = error?.response?.data?.message
                                                               toast.error(message ? message : 'Server Error!', {
                                                                  autoClose: 5000,
                                                               });
                                                         } catch (error) {
                                                               
                                                         }
                                                      })
                                                   } catch (error) {
                                                      toast.error('Server error.', {
                                                         autoClose: 5000,
                                                      });
                                                   }
                                             } else if (result.isDenied) {
                                                   // Swal.fire('Changes are not saved', '', 'info')
                                             }
                                          })
                                       }}
                                 >
                                    Resend Label Request
                                 </button>
                              </div>
                            )}

                           </>
                        ) : (
                           <></>
                        )}

                     </div>
                  </div>
               )}

            </div>
         )
         }
         <br />
         <br />
         <br />
         <br />
      </>
   )
}

export default withParams(withNavigation(Index))