// Countries added here should adhere to ISO 3166-1 alpha-2 codes:
// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes

const SHIPMENT_COUNTRIES = [
    {
        name: 'United States',
        code: 'US',
    },
    {
        name: 'Austria',
        code: 'AT',
    },
    {
        name: 'Australia',
        code: 'AU',
    },
    {
        name: 'Barbados',
        code: 'BB',
    },
    {
        name: 'Belgium',
        code: 'BE',
    },
    {
        name: 'Botswana',
        code: 'BW',
    },
    {
        name: 'Brazil',
        code: 'BR',
    },
    {
        name: 'Bulgaria',
        code: 'BG',
    },
    {
        name: 'Canada',
        code: 'CA',
    },
    {
        name: 'Cambodia',
        code: 'KH',
    },
    {
        name: 'Colombia',
        code: 'CO',
    },
    {
        name: 'Croatia',
        code: 'HR',
    },
    {
        name: 'Czech Republic',
        code: 'CZ',
    },
    {
        name: 'Denmark',
        code: 'DK',
    },
    {
        name: 'Egypt',
        code: 'EG',
    },
    {
        name: 'Estonia',
        code: 'EE',
    },
    {
        name: 'France',
        code: 'FR',
    },
    {
        name: 'Hong Kong',
        code: 'HK',
    },
    {
        name: 'Hungary',
        code: 'HU',
    },
    {
        name: "Ireland",
        code: 'IE',
    },
    {
        name: 'Italy',
        code: 'IT',
    },
    {
        name: 'Japan',
        code: 'JP',
    },
    {
        name: 'Luxembourg',
        code: 'LU',
    },
    {
        name: 'Germany',
        code: 'DE',
    },
    {
        name: 'Malaysia',
        code: 'MY',
    },
    {
        name: 'Mexico',
        code: 'MX',
    },
    {
        name: 'Namibia',
        code: 'NA',
    },
    {
        name: 'Netherlands',
        code: 'NL',
    },
    {
        name: 'New Zealand',
        code: 'NZ',
    },
    {
        name: 'Puerto Rico',
        code: 'PR',
    },
    {
        name: 'Panama',
        code: 'PA',
    },
    {
        name: 'Romania',
        code: 'RO',
    },
    {
        name: 'Serbia',
        code: 'RS',
    },
    {
        name: 'Slovakia',
        code: 'SK',
    },
    {
        name: 'South Africa',
        code: 'ZA',
    },
    {
        name: 'South Korea',
        code: 'KR',
    },
    {
        name: 'Spain',
        code: 'ES',
    },
    {
        name: 'Switzerland',
        code: 'CH',
    },
    {
        name: 'Portugal',
        code: 'PT',
    },
    {
        name: 'Taiwan',
        code: 'TW',
    },
    {
        name: 'Tanzania',
        code: 'TZ',
    },
    {
        name: 'United Kingdom',
        code: 'GB',
    },
    {
        name: 'Ukraine',
        code: 'UA',
    },
    {
        name: 'Vietnam',
        code: 'VN',
    },
    {
        name: 'Zimbabwe',
        code: 'ZW',
    }
];

export default SHIPMENT_COUNTRIES;
